import { useCallback } from 'react';

import useIsLargeScreen from '~/app/lib/hooks/useIsLargeScreen';

import Button, { ButtonProps } from '../Button';
import ChevronIcon from '../Icon/ChevronIcon';

import { SelectButtonProps, SelectOption } from './types';

const SelectButton = <T extends SelectOption>({
  testId,
  isOpened,
  isEmpty,
  open,
  ...buttonProps
}: SelectButtonProps<T> & Omit<ButtonProps, 'isInverted'>) => {
  const isLargeScreen = useIsLargeScreen();

  return (
    <Button
      {...buttonProps}
      testId={`${testId}Button`}
      withDisabledStyle={false}
      isUppercase={false}
      isCentered={false}
      textProps={{
        color: isEmpty ? '#999' : '#fff',
        margin: '0 1.9rem 0 0',
        size: isLargeScreen ? '1.4rem' : '1.7rem',
      }}
      renderAfter={useCallback(
        () => (
          <ChevronIcon size="1.4rem" direction={isOpened ? 'up' : 'down'} />
        ),
        [isOpened]
      )}
      onClick={open}
    />
  );
};

export default SelectButton;
