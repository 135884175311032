import { memo, useLayoutEffect, useState } from 'react';

import useIsLargeScreen from '~/app/lib/hooks/useIsLargeScreen';
import { useI18nDynamic } from '~/app/lib/i18n';

import Scroller2, { useScroller } from '../Scroller2';
import { useAppToast } from '../NextApp/lib/CoreUi';
import { NotificationType } from '../Notification';
import Text from '../Text';
import Box from '../Box';

import { SelectOption, SelectOptionsProps } from './types';
import SelectOptionButton from './SelectOptionButton';

const INITIAL_OPTIONS_COUNT = 25;
const ANIMATION_DURATION = 300;

const SelectOptions = <T extends SelectOption>({
  query,
  close,
  testId = 'select',
  options,
  inputRef,
  onChange,
  renderOption,
  optionHeight: baseOptionHeight,
  searchFeature,
  selectedOption,
  visibleOptionsCount,
}: SelectOptionsProps<T>) => {
  const [optionsToRender, setOptionsToRender] = useState(
    // load the first chunk of options initially
    options.slice(0, INITIAL_OPTIONS_COUNT)
  );

  const [isInitialRender, setIsInitialRender] = useState(true);
  const trimmedQuery = query.trim();

  const { t } = useI18nDynamic('select');
  const isLargeScreen = useIsLargeScreen();
  const appToast = useAppToast();

  const optionHeight = isLargeScreen
    ? baseOptionHeight
    : `calc(${baseOptionHeight} + .8rem)`;

  // show extra half of the option height to identify options are scrollable
  const VISIBLE_OPTIONS_HEIGHT = `calc(${optionHeight} * ${visibleOptionsCount} + ${optionHeight} / 2)`;
  const CONTENT_HEIGHT = isLargeScreen ? VISIBLE_OPTIONS_HEIGHT : '100%';

  useLayoutEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (isInitialRender) {
      // show the full list of options after dropdown animation completes
      timeoutId = setTimeout(() => {
        setIsInitialRender(false);
        setOptionsToRender(options);
      }, ANIMATION_DURATION);
    } else {
      setOptionsToRender(options);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [options, isInitialRender]);

  const Content = () => {
    const scroller = useScroller();

    const isCreateOptionVisible =
      searchFeature?.type === 'create' &&
      trimmedQuery &&
      options.every(({ text }) => text !== trimmedQuery);

    useLayoutEffect(() => {
      // Reset scroll only if options scrolled
      if (scroller?.getScrollTop()) {
        // scroll to the top when options gets updated
        // it fix stucking in the middle of the list during search
        scroller?.setScrollTop(0);

        // setTimeout ensures focus is called after the render pass
        setTimeout(() => {
          inputRef.current?.focus();
        });
      }
    }, []);

    return (
      <Box flexColumn minHeight={searchFeature ? CONTENT_HEIGHT : '100%'}>
        {isCreateOptionVisible && (
          <SelectOptionButton
            testId={`${testId}Create`}
            noFlexShrink
            padding={isLargeScreen ? '1.6rem 1rem' : '2rem'}
            alignCenter
            onClick={() => {
              searchFeature
                .action({ query: trimmedQuery, close })
                .then((id) => {
                  // NOTE: Set new value after create action
                  onChange(id);
                })
                .catch((error: Error) => {
                  appToast({
                    type: NotificationType.ERROR,
                    text: error.message,
                  });
                });
            }}
          >
            <Text
              isBold
              color="#fff"
              size={isLargeScreen ? '1.4rem' : '1.7rem'}
            >
              {searchFeature.text?.(trimmedQuery) ??
                t('addOption', { name: trimmedQuery })}
            </Text>
          </SelectOptionButton>
        )}
        {options.length ? (
          <Box flexColumn>
            {optionsToRender.map((option) => {
              const isSelected = option.id === selectedOption?.id;

              return (
                renderOption?.({
                  ...option,
                  isSelected,
                  height: optionHeight,
                  onChange,
                  close,
                }) ?? (
                  <SelectOptionButton
                    testId={`${testId}Option`}
                    key={option.id}
                    height={optionHeight}
                    padding={`0 ${isLargeScreen ? '1rem' : '1.6rem'}`}
                    flexRow
                    alignCenter
                    style={{
                      backgroundColor: isSelected ? '#303030' : '#1a1a1a',
                    }}
                    onClick={() => {
                      onChange(option.id);
                      close();
                    }}
                  >
                    {option.Icon && (
                      <option.Icon
                        noFlexShrink
                        color="#fff"
                        margin="0 1rem 0 0"
                      />
                    )}
                    <Box
                      flexColumn
                      style={{ overflow: 'hidden', gap: '.4rem' }}
                    >
                      <Text
                        color="#fff"
                        size={isLargeScreen ? '1.4rem' : '1.7rem'}
                        isBold={isSelected}
                        withEllipsis
                      >
                        {option.text}
                      </Text>
                      {option.caption && (
                        <Text
                          color="#999"
                          size={isLargeScreen ? '1.1rem' : '1.3rem'}
                          withEllipsis
                        >
                          {option.caption}
                        </Text>
                      )}
                    </Box>
                  </SelectOptionButton>
                )
              );
            })}
          </Box>
        ) : (
          <Box
            flexColumn
            centerContent
            padding={isLargeScreen ? '2rem' : '8rem 2rem'}
            style={{ flexGrow: 1 }}
          >
            <Text
              color="#999"
              size={isLargeScreen ? '1.2rem' : '1.5rem'}
              centered
            >
              {searchFeature ? t('noResults') : t('noOptions')}
            </Text>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Scroller2
      testId={`${testId || 'select'}Scroller`}
      flexGrow
      fullHeight={false}
      maxHeight={CONTENT_HEIGHT}
      style={{ overflow: 'hidden' }}
      contentStyle={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: searchFeature ? CONTENT_HEIGHT : '100%',
      }}
      onScrollStart={() => {
        // NOTE: unfocus input hides keyboard on mobile devices
        inputRef.current?.blur();
      }}
    >
      <Content />
    </Scroller2>
  );
};

export default memo(SelectOptions);
