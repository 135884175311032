import classNames from 'classnames';
import { ReactNode } from 'react';
import css from 'styled-jsx/css';

import withSpacing, { WithSpacingProps } from '~/app/lib/hocs/withSpacing';
import prettyWrap from '~/app/lib/utils/prettyWrap';
import useTheme from '~/app/lib/hooks/useTheme';
import Text, { TextProps } from '../Text';
import Box from '../Box';

export interface InputLabelProps extends WithSpacingProps {
  value?: string | JSX.Element;
  description?: string | JSX.Element;
  textCentered?: boolean;
  renderAfter?: () => JSX.Element | undefined;
  tag?: 'div';
  children: ReactNode;
}

export const INPUT_LABEL_FONT_SIZE = '1.35rem';

const descriptionStyle = css.resolve`
  .withLinkStyling :global(a),
  .withLinkStyling :global(button) {
    display: inline-block;
    color: #888;
    border-bottom: dotted 1px #666;
  }

  .withLinkStyling :global(a):hover,
  .withLinkStyling :global(button):hover {
    color: #fff;
  }
`;

export const InputLabelValue = ({
  value,
  textCentered,
  renderAfter,
}: Pick<InputLabelProps, 'value' | 'textCentered' | 'renderAfter'> & {
  value: string | JSX.Element;
  withLinkStyling?: boolean;
}) => {
  const theme = useTheme();

  return (
    <Text
      flexRow
      className="title"
      isBold
      size={INPUT_LABEL_FONT_SIZE}
      color={theme.textColor70}
      letterSpacing={0.01}
      margin="0 0.1rem 0.8rem"
      centered={textCentered}
      style={{
        scrollMarginTop: '1.2rem',
      }}
    >
      {value}
      {renderAfter && <Box margin="0 0 0 auto">{renderAfter?.()}</Box>}
    </Text>
  );
};

export const InputLabelDescription = ({
  value,
  withLinkStyling = true,
  ...textProps
}: Omit<TextProps, 'children'> & {
  value: string | JSX.Element;
  withLinkStyling?: boolean;
}) => {
  const theme = useTheme();

  return (
    <Text
      className={classNames(descriptionStyle.className, { withLinkStyling })}
      color={theme.textColor60}
      size={INPUT_LABEL_FONT_SIZE}
      lineHeight={1.3}
      letterSpacing={0.01}
      margin="0.7rem 0 0"
      {...textProps}
    >
      {prettyWrap(value)}
      {descriptionStyle.styles}
    </Text>
  );
};

const InputLabel = withSpacing<InputLabelProps>(
  ({
    style,
    className,
    value,
    description,
    children,
    textCentered,
    tag,
    renderAfter,
  }) => {
    const Tag = tag ?? 'label';

    return (
      <Tag style={{ display: 'block', ...style }} className={className}>
        {value && (
          <InputLabelValue
            value={value}
            textCentered={textCentered}
            renderAfter={renderAfter}
          />
        )}
        {children}
        {description && (
          <InputLabelDescription centered={textCentered} value={description} />
        )}
        <style jsx>{`
          :focus-within :global(.title) {
            color: #ddd !important;
          }
        `}</style>
      </Tag>
    );
  }
);

export default InputLabel;
